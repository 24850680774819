import React,{ useEffect, useState} from "react";
import api from '../../services/api'
import Regions from '../../data/Regions';


 function ApiInfo(props){
    

}

export default ApiInfo

